import {getInstance} from "@/helpers/axios.service";
import {endPoint} from "@/helpers/Endpoint";

export const toolCategoryService = {
    get, gets, add, update, statusChange
};

async function get(request = {}) {
    return await getInstance(endPoint.toolCategoryGet, request);
}

async function gets(request = {}) {
    return await getInstance(endPoint.toolCategoryGets, request);
}

async function add(request = {}) {
    return await getInstance(endPoint.toolCategoryAdd, request);
}

async function update(request = {}) {
    return await getInstance(endPoint.toolCategoryUpdate, request);
}

async function statusChange(request = {}) {
    return await getInstance(endPoint.toolCategoryStatusChange, request);
}
